import baseTheme from "gatsby-theme-blog/src/gatsby-plugin-theme-ui/index"

export default {
    ...baseTheme,
    styles: {
        ...baseTheme.styles,
        ul: {
            ul: {
                li: {
                    lineHeight: "1.0em",
                }
            }
        }
    }
}